<template>
  <div class="activity_home">
    <div class="banner">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in hd.banner" :key="index">
          <img v-lazy="image" width="100%"/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="container">
      <div class="countDown">
        <van-count-down :time="time">
          <template #default="timeData">
            <span v-if="hd.state==2">活动已结束</span>
            <template v-else>
              <span>距{{hd.state==0?'开始':'结束'}}：</span>
              <span class="block">{{ timeData.days }}</span>
              <span class="colon">天</span>
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">时</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">分</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">秒</span>
            </template>
          </template>
        </van-count-down>
      </div>
      
      <van-row class="user_statistics">
        <van-col span="8" class="item">
          <label>{{hd.total_user}}</label>
          <span>已参与</span>
        </van-col>
        <van-col span="8" class="item">
          <label>{{hd.seckill_tj_num}}</label>
          <span>已购买</span>
        </van-col>
        <van-col span="8" class="item">
          <label>{{hd.amount}}</label>
          <span>已阅读</span>
        </van-col>
      </van-row>

      <goodList :order_type="2" :dataList="goodList" :data="hd" v-if="goodList.length"/>
      
      <div class="index_box">
        <div class="column">
          <div class="h4">他们很感兴趣</div>
        </div>
        <userList :hdid="hd.id"/>
      </div>

      <div class="index_box" v-if="hd.seckill_tj_num>0">
        <div class="column">
          <div class="h4">已有 <em>{{hd.seckill_tj_num}}</em> 人购买</div>
        </div>
        <orderuser :hdid="hd.id"/>
      </div>
      
      <div class="index_box" v-if="hd.content != null && hd.content != ''">
        <div class="column">
          <div class="h4">活动介绍</div>
        </div>
        <div class="textContent" v-html="hd.content"></div>
      </div>
      
      <div class="mt10"><shopInfo :data="hd.shopInfo"/></div>

    </div>
  </div>
</template>

<script>
  import shopInfo from '@/pages/wx/components/shopInfo'
  import goodList from '@/pages/wx/components/goodList'
  import userList from '@/pages/wx/components/userList'
  export default {
    props: {
      hd:Object,
      goodList:Array
    },
    components: {goodList,userList,shopInfo},
    data() {
      return {
        time:0
      }
    },
    activated() {
      this.setBgColor()
    },
    created(){
      this.setBgColor()
    },
    mounted() {
      this.time = new Date(this.hd.end_time).getTime() - new Date().getTime();
    },
    methods: {
      setBgColor(){
        if(this.hd.bgcolor==null || this.hd.bgcolor==''){
          document.querySelector('body').setAttribute('style', 'background-color:#ffe7f0')
        }
      },
    }
  }
</script>
<style lang="less">
  .activity_home{
    .banner{background:url('../image/template3_bg.jpg') no-repeat top center;background-size:100% auto;padding:10px;}
    .container{padding:0px 10px 10px 10px;}
    .column{height:auto;text-align:center;padding:20px 0px;
      .h4{color:#fff;font-size:18px;font-weight:700;}
    }
    .countDown{background:linear-gradient(to right,#e79b00, #ff518e);border-radius:4px;box-shadow:0px 4px 4px rgba(242,36,67,0.3);color:#fff;padding:15px;position:relative;margin-bottom:20px;
      &:after{content:'';width:calc(100% + 10px);height:calc(100% + 18px);background:url('../image/template3_countDown.png') no-repeat center top;background-size:100% auto;position:absolute;left:-5px;top:-9px;z-index:1;}
      .van-count-down{text-align:center;height:44px;border:2px dashed #fff;border-radius:4px;color:#fff;padding-top:24px;line-height:36px;}
      .block{background:url('../image/template3_countDown_icon.png') no-repeat center top;background-size:100% auto;padding:0px;width:36px;height:36px;}
    }
    .user_statistics{background:#fff;border-radius:4px;padding:15px 0px;height:auto;margin-top:10px;
      .item{text-align:center;
        label{color:#f24374;font-size:20px;width:100%;display:inline-block;font-weight:700;}
        span{width:100%;display:inline-block;margin-top:5px;color:#666;}
      }
    }
    .goodList{
      .item{
        .action{
          .van-button--danger{background:#f24374;border:1px solid #f24374;}
        }
      }
    }
    .userList{
      .item{color:#fff !important;}
    }
    .index_box{background:linear-gradient(to right,#f24273, #fd8baa);border-radius:4px;position:relative;margin-top:100px;padding:10px;
      &:after{content:'';width:60%;height:88px;background:url('../image/template3_lmbg.png') no-repeat center top;background-size:100% auto;position:absolute;left:20%;top:-74px;z-index:1;}
    }
  }
  
  .footerAction{
    .btn1{background:#e79b00;border:1px solid #e79b00;}
    .btn2{background:#f24374;border:1px solid #f24374;}
  }
</style>
