<template>

  <div class="goodList" v-if="goodList.length>0">
    <div class="big" v-if="show_type==1">
      <div v-for="item in goodList" :key="item.id" :class="['item',item.stock<=0?'sell_out':'']">
        <img :src="require('@/assets/image/icon_sell_'+item.label+'.png')" v-if="item.label>0" class="iconSell"/>
        <div class="img"><van-image width="100%" lazy-load :src="item.titleimg"/></div>
        <div class="ginfo">
          <div class="title">
            <span class="package" v-if="item.package_key>0">[{{item.package_name}}]</span>
            <span class="t">{{item.title}}</span>
            <span class="max_pay" v-if="item.max_paynum>0">(限购{{item.max_paynum}}{{item.dw}})</span>
          </div>
          <div class="price">
            <span v-if="item.now_price>0">在线支付<em>{{item.now_price}}</em>元</span>
            <label class="price" v-else>免费送</label>
          </div>
        </div>
        <van-divider dashed :style="{borderColor: '#ccc',margin: '8px 0px'}"/>
        <div class="action" v-if="setParams.action">
          <van-row type="flex" justify="center" :gutter="5">
            <van-col :span="(data.state==1 && item.remarks != '')?9:12" v-if="item.content != ''"><van-button type="info" size="small" block round @click="onDetail(item)">查看详情</van-button></van-col>
            <van-col :span="(data.state==1 && item.remarks != '')?15:12">
              <van-button type="danger" size="small" block round @click="goCart(item)" v-if="data.state==1">{{item.remarks==''?'立即抢购':item.remarks}}</van-button>
              <van-button type="danger" size="small" block round :color="data.state==0?'#07c160':'#888'" v-else>{{data.state==0?'即将开始':'活动已结束'}}</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div class="middle" v-if="show_type==2">
      <div v-for="item in goodList" :key="item.id" :class="['item',item.stock<=0?'sell_out':'']">
        <img :src="require('@/assets/image/icon_sell_'+item.label+'.png')" v-if="item.label>0" class="iconSell"/>
        <van-row type="flex" :gutter="10" class="ginfo">
          <van-col span="6" class="img"><van-image width="100%" lazy-load :src="item.titleimg"/></van-col>
          <van-col span="18" class="info">
            <div class="title">
              <span class="package" v-if="item.package_key>0">[{{item.package_name}}]</span>
              <span class="t">{{item.title}}</span>
              <span class="max_pay" v-if="item.max_paynum>0">(限购{{item.max_paynum}}{{item.dw}})</span>
            </div>
            <div class="price">
              <span v-if="item.now_price>0" class="now_price"><van-icon class-prefix="ico" name="rmb" /><em>{{item.now_price}}</em></span>
              <span v-else>免费送</span>
              <s v-if="setParams.old_price"><van-icon class-prefix="ico" name="rmb" />{{item.old_price}}</s>
            </div>
            <div class="stock" v-if="setParams.is_stock">仅剩{{item.stock}}{{item.dw}}</div>
            <!--<p class="font-color-yellow mt5" v-if="setParams.is_prize"><van-icon class-prefix="ico" name="smile" /> {{item.tj_num}} 人抽中</p>-->
          </van-col>
        </van-row>
        <div class="action" v-if="setParams.action">
          <van-row type="flex" justify="center" :gutter="5">
            <van-col :span="(data.state==1 && item.remarks != '')?9:12" v-if="item.content != ''"><van-button type="info" size="small" block round @click="onDetail(item)">查看详情</van-button></van-col>
            <van-col :span="(data.state==1 && item.remarks != '')?15:12">
              <van-button type="danger" size="small" block round @click="goCart(item)" v-if="data.state==1">{{item.remarks==''?'立即抢购':item.remarks}}</van-button>
              <van-button type="danger" size="small" block round :color="data.state==0?'#07c160':'#888'" v-else>{{data.state==0?'即将开始':'活动已结束'}}</van-button>
            </van-col>
          </van-row>
        </div>
  
      </div>
      
    </div>
    <div class="small" v-if="show_type==3">
      
      <van-row type="flex" :gutter="10">
        <van-col span="8" v-for="item in goodList" :key="item.id" :class="['item',item.stock<=0?'sell_out':'']">
          <img :src="require('@/assets/image/icon_sell_'+item.label+'.png')" v-if="item.label>0" class="iconSell"/>
          <div class="img"><img width="100%" lazy-load :src="item.titleimg"/></div>
          <div class="title">{{item.title}}</div>
          <div class="price">
            <span v-if="item.now_price>0" class="now_price"><van-icon class-prefix="ico" name="rmb" /><em>{{item.now_price}}</em></span>
            <span v-else>免费送</span>
          </div>
          
        </van-col>
      </van-row>
      
    </div>

    <van-popup v-model="detailModal" position="bottom" closeable :style="{ height: '100%' }">
      <div class="goodDetail">
        <div class="title">{{currentInfo.title}}</div>
        <van-row type="flex" justify="space-between" align="bottom" class="other">
          <van-col class="price">在线支付<span>{{currentInfo.now_price}}</span>元，<s>原价：{{currentInfo.old_price}}元</s></van-col>
          <van-col>库存{{currentInfo.stock}}{{currentInfo.dw}}</van-col>
        </van-row>
        <div class="textContent mt10" v-html="currentInfo.content"></div>
    
        <van-popup :value="data.state==1?true:false" position="bottom" :overlay="false" :lock-scroll="false">
          <div class="action"><van-button type="primary" class="btn2" size="small" block @click="goCart(currentInfo)">立即购买</van-button></div>
        </van-popup>
      </div>
    </van-popup>
  </div>

</template>
<script>
import {GetgoodList} from "../services/services";
export default {
  props: {
    dataList:Array,
    data:Object,
    type:Number,
    config:Object
  },
  components: {},
  data() {
    return {
      setParams:{
        action:true,
        is_stock:true,
        now_price:true,
        old_price:true,
        is_prize:false
      },
      goodList:[],
      show_type:0,
      detailModal:false,
      currentInfo:{}
    }
  },
  created(){
    this.setParams={
      ...this.setParams,
      ...this.config
    }
    this.show_type=this.data.show_type!=undefined?this.data.show_type:(this.setParams.action?this.data.seckill_show_type:2)
  },
  mounted() {

    if(this.dataList == undefined){
      this.findgoodList()
    }else{
      this.goodList = this.dataList
    }
    
  },
  methods: {
    findgoodList(){
      GetgoodList({hdid:this.data.id,type:this.type}).then(result => {
        if(result.data.code==1){
          this.goodList=result.data.data
          this.$emit('getgoodCount',this.goodList.length)
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    onDetail(record){
      this.currentInfo = record
      this.detailModal=true
    },
    goCart(record){
      if(parseInt(process.env.VUE_APP_PRODUCTION)){
        location.href = process.env.VUE_APP_PAY_URL+'/wx/cart/'+record.id
      }else{
        this.$router.push({path:'/wx/cart/'+record.id})
      }

    }
  }
}
</script>
<style lang="less" scoped>
  @import "@/assets/css/good.less";
</style>
