<template>
  <van-row class="userList" type="flex">
    <van-col span="4" v-for="item in dataList" :key="item.id" class="item">
      <p><van-image width="100%" :src="item.avatarUrl" round/></p>
      <p class="ellipsis">{{item.nickName}}</p>
    </van-col>
  </van-row>
</template>
<script>
import {GetuserList} from "../services/services";
export default {
  props: {
    hdid:Number
  },
  components: {},
  data() {
    return {
      dataList:[]
    }
  },
  mounted() {
    this.findList()
  },
  methods: {
    findList(){
      const params = {
        searchbean:{hdid:this.hdid},
        pageIndex:1,
        pageSize:30
      }
      GetuserList(params).then(result => {
        if(result.data.code==1){
          const {list} = result.data.data
          this.dataList = list
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
  .userList{
    .item{text-align:center;color:#555;font-size:12px;padding:8px;}
  }
</style>
